import React from "react";
import { useNavigate } from "react-router-dom";
import TGIcon from "../../../../../../shared/tg-icon";
import styles from "./profile.module.scss";
import { useTranslation } from "react-i18next";
 
interface Props {
  title: any;
  className?: string;
}
 
export const SideMenu: React.FC<Props> = (props: Props) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
 
  const handleClick = (title: any) => {
    if (title.value === "Change Password")
      navigate(`/${i18n.language}/myprofile/changepassword`);
  };
 
  return (
    <div className={props.className}>
      <div
        onClick={() => handleClick(props.title)}
        className={styles.sideMenuText}
      >
        {props.title.label}
      </div>
      <div
        onClick={() => handleClick(props.title)}
        className={styles.iconClick}
      >
        <TGIcon icon="arrow-member-icon" fillColor="" size="none" />
      </div>
    </div>
  );
};