import React, { useEffect, useRef, useState } from "react";
import TGIcon from "../../../../../../shared/tg-icon";
import styles from "./profile.module.scss";
import { Form, Offcanvas, Tab, Tabs } from "react-bootstrap";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import TGButtonVariants from "../../../../../../shared/tg-button-variants";
import { SideMenu } from "./sideMenu";
import { PersonalInfo } from "../personal-info-component";
import { ContactInfo } from "../contact-info-Component";
import { LifeStyle } from "../life-style-component";
import { TGRadio } from "../../../../../../shared/tg-radio";
import gsap from "gsap";
import { fetchMyProfileRequest } from "../../../../slice/fetchMainMemberProfileSlice";
import { fetchCrmMemberProfileRequest } from "../../../../slice/fetchCrmProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../slice/RootReducer";
import { fetchMyProfileMilesRequest } from "../../../../slice/fetchMilesSlice";
import { fetchProfileRequest } from "../../../../slice/fetchProfileSlice";
import { setIsLifeStyleEditable } from "../../../../slice/profileLifeStyleSlice";
import {
  setIsEditable,
  setProfileIsEditable,
} from "../../../../slice/profileContactInfoSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { NomineeInfo } from "../nominee-info";
import { useLocation } from "react-router-dom";
import { updateNomineeTabState } from "../../../../slice/fetchNomineeSlice";
import { useNavigate } from "react-router";
import TGButton from "../../../../../../shared/tg-button";
import TGModal from "../../../../../../shared/tg-modal";
import { setIsPersonalInfoEditable } from "../../../../slice/profilePersonalInfoSlice";
import { fetchLifeStyleRequest } from "../../../../slice/fetchLifeStyleSlice";
import DownloadApp from "../../../../component/DownloadApp";
import { config } from "../../../../../../config/global";
import AppStore from "../../../../../../../public/assets/modalAppStore.svg";
import PlayStore from "../../../../../../../public/assets/modalPlayStore.svg";
import HauweiStore from "../../../../../../../public/assets/modalHauweiStore.svg";

interface Props {
  title?: string;
  tabbedChildren?: any;
  miniProfileChildren?: any;
  profileName?: string;
}
gsap.registerPlugin();
export const MyProfile = (props: Props) => {
  const dispatch = useDispatch();
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [showToastUpdate, setShowToastUpdate] = useState(false);
  const [showChangePasswordToast, setShowChangePasswordToast] = useState(false);
  const [showEditButton, setShowEditButton] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [pClass, setPclass] = useState("Personal Info");
  const [isProfile, setIsProfile] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [clickedTab, setClickedTab] = useState<any>(null);
  const { activedTab } = location.state || 0;
  const { nomineeTabState } = useSelector(
    (state: any) => state?.osciReducer?.nominee
  );
  const [isCrm, setIsCrm] = useState(false);
  const { t, i18n } = useTranslation();
  const profileResponse = useSelector(
    (state: RootState) =>
      state?.osciReducer?.MyProfileMilesDeails.myprofileMilesData
  );
  const crmProfileResponse = useSelector(
    (state: RootState) =>
      state?.osciReducer?.fetchCrmMemberProfile.crmMemberprofileData
  );
  const milesArr = profileResponse?.milesExpiry || [];
  const memberIdResponse = useSelector(
    (state: RootState) => state?.osciReducer?.MyProfileDeails.myprofileData
  );
  useEffect(() => {
    props.profileName === "CRM" && setIsCrm(true);
  }, [props.profileName]);

  const { isLoading } = isCrm
    ? useSelector(
        (state: RootState) => state?.osciReducer?.fetchCrmMemberProfile
      )
    : useSelector((state: RootState) => state?.osciReducer?.MyProfileDeails);

  const privilageCard = useSelector(
    (state: RootState) => state?.osciReducer?.profile.profileData.privilegeCard
  );

  const isContactEditable = useSelector(
    (state: RootState) => state?.osciReducer?.profileContactInfo.isEditable
  );

  const isLifestyleEditable = useSelector(
    (state: RootState) =>
      state?.osciReducer?.profileLifeStyleSubmitResponse.setIsLifeStyleEditable
  );

  const isPersonalEditable = useSelector(
    (state: RootState) => state?.osciReducer?.profilePersonalInfo.isEditable
  );

  const queryParams = window.location.href;

  const sideMenuValues = [
    {
      label: t("label_signedin_rop_change_password"),
      value: "Change Password",
    },
  ];

  const getInitialValue = () => {
    return pClass;
  };
  const [value, setValue] = useState(getInitialValue());

  const [selected, setSelected] = useState(1);
  const handleTabClick = (index: any) => {
    if (
      (activeTab === 0 && isPersonalEditable) ||
      (activeTab === 1 && isContactEditable) ||
      (activeTab === 2 && isLifestyleEditable)
    ) {
      setClickedTab(index);
      setShowModal(true);
    } else {
      if (index !== 3) {
        location.state = 0;
      }
      setActiveTab(index);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLeaveModal = () => {
    if (isDesktopView) {
      setShowModal(false);
      setActiveTab(clickedTab);
    } else {
      setShowModal(false);
      setPclass(radioData[clickedTab - 1]?.label);
    }
  };

  const handleStayModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (window?.sessionStorage.getItem("isROPLoggedIn") == "true") {
      dispatch(fetchMyProfileRequest());
      dispatch(fetchProfileRequest());
      dispatch(fetchLifeStyleRequest());
    } else {
      dispatch(fetchCrmMemberProfileRequest());
    }
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(nomineeTabState).length > 0) {
      if (isDesktopView) {
        setActiveTab(nomineeTabState.tabValue);
      } else if (!isDesktopView) {
        setPclass("");
      }
    } else {
      if (queryParams && queryParams.includes("?")) {
        setActiveTab(3);
        setSelected(4);
        setPclass("");
      } else {
        setActiveTab(0);
      }
    }
    if (pClass == "Personal Info") {
      setSelected(1);
      setValue("Personal Info");
    } else if (pClass == "Contact Info") {
      setSelected(2);
      setValue("Contact Info");
    } else if (pClass == "Lifestyle") {
      setSelected(3);
      setValue("Lifestyle");
    } else {
      setSelected(4);
      setValue(t("label_nominee_header"));
    }
  }, [selected, pClass, isOpen]);

  const handleClassChange = (c: any) => {
    if (
      (selected === 1 && isPersonalEditable) ||
      (selected === 2 && isContactEditable) ||
      (selected === 3 && isLifestyleEditable)
    ) {
      setShowModal(true);
      setClickedTab(c?.id);
    } else {
      setPclass(c?.label);
      setShowModal(false);
    }
    if (nomineeTabState) {
      dispatch(updateNomineeTabState({}));
    }
    setValue(pClass);
    setIsOpen(false);
    document.documentElement.style.setProperty("--carousel-z-index", "unset");
    document.documentElement.style.setProperty("--body-overflow", "auto");
    setShowEditButton(true);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(memberIdResponse.memberID);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 2500);
  };

  //InputRef variable
  const inputRef = useRef<any>(null);
  const profileScrollRef = useRef<any>(null);
  const editScrollRef = useRef<any>(null);
  const [lastScroll, setLastScroll] = useState(0);
  const [showDownloadAppModal, setShowDownloadAppModal] =
    useState<boolean>(false);
  const [device, setDevice] = useState({
    isDesktop: false,
    isAndroid: false,
    isIOS: false,
  });
  const [qrCode, setQrCode] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      setDevice({ isDesktop: false, isAndroid: true, isIOS: false });
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setDevice({ isDesktop: false, isAndroid: false, isIOS: true });
    } else {
      setDevice({ isDesktop: true, isAndroid: false, isIOS: false });
    }
  }, []);

  useEffect(() => {
    if (showDownloadAppModal) {
      document.documentElement.style.setProperty("--html-position", "fixed");
    } else {
      document.documentElement.style.setProperty("--html-position", "auto");
    }
  }, [showDownloadAppModal]);

  useEffect(() => {
    const handleScroll = () => {
      const headerScroll =
        window.pageYOffset || document.documentElement.scrollTop;
      if (Math.abs(headerScroll - lastScroll) > 10) {
        // to check the scroll to prevent minor jumps
        if (headerScroll > 1059 && headerScroll > lastScroll) {
          if (headerScroll > 1000) {
            //  to check the header to pass the some distance to start the animation
            setIsProfile(true);
          }
          gsap.to(profileScrollRef.current, { y: "-100%", duration: 0.25 });
          gsap.to(editScrollRef.current, {
            y: "10%",
            duration: 0.25,
            padding: "20px",
          });
        }
        if (headerScroll < 1056 || headerScroll < lastScroll) {
          gsap.to(profileScrollRef.current, { y: "0%", duration: 0.25 });
          gsap.to(editScrollRef.current, { y: "0%", duration: 0.25 });
          if (headerScroll < 1018) {
            //  header sets to relative when screen reaches 300px or less
            setIsProfile(false);
          }
        }
        setLastScroll(headerScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScroll]);
  const radioData = [
    {
      id: 1,
      label: "Personal Info",
    },
    {
      id: 2,
      label: "Contact Info",
    },

    {
      id: 3,
      label: "Lifestyle",
    },

    {
      id: 4,
      label: t("label_nominee_header"),
    },
  ];

  const crmRadioData = [
    {
      id: 1,
      label: "Personal Info",
    },
    {
      id: 2,
      label: "Contact Info",
    },
  ];
  const renderChild = () => {
    return (
      <div
        className={`d-flex global-content-margin ${
          !isDesktopView && "flex-column"
        } ${styles["passenger-class-child-wrapper"]}`}
      >
        <div className={styles["passenger-class-header-wrapper"]}>
          <TGRadio
            options={isCrm ? crmRadioData : radioData}
            selected={selected}
            onChange={(opt: any) => handleClassChange(opt)}
            name="class"
            isBorder={true}
            align="column"
          />
        </div>
      </div>
    );
  };
  const renderMenu = () => {
    return (
      <div>
        <Offcanvas
          show={isOpen}
          onHide={() => {
            setIsOpen(false);
            document.documentElement.style.setProperty(
              "--body-overflow",
              "auto"
            );
            document.documentElement.style.setProperty(
              "--carousel-z-index",
              "-1"
            );
          }}
          placement="bottom"
          className={styles["tg-select-passenger-offcanvas"]}
          onShow={() =>
            document.documentElement.style.setProperty(
              "--body-overflow",
              "hidden"
            )
          }
        >
          <Offcanvas.Header
            closeButton
            className={styles["tg-select-passenger-offcanvas-header"]}
          >
            <Offcanvas.Title
              className={styles["tg-select-passenger-offcanvas-title"]}
            >
              {t("label_myprofile")}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>{renderChild()}</Offcanvas.Body>
        </Offcanvas>
      </div>
    );
  };

  useEffect(() => {
    dispatch(fetchMyProfileMilesRequest());
  }, [dispatch]);

  const profContactInfoSubmitResp = useSelector(
    (state: RootState) => state?.osciReducer?.profileContactInfo
  );

  const profPersonalInfoSubmitResp = useSelector(
    (state: RootState) => state?.osciReducer?.profilePersonalInfo
  );

  const myLifeStyleSubmitResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profileLifeStyleSubmitResponse
  );

  const changePasswordResponse = useSelector(
    (state: RootState) => state?.osciReducer?.changePasswordotpData
  );

  useEffect(() => {
    if (
      myLifeStyleSubmitResponse?.profileLifeStyleInfoStatus?.status === true &&
      !isDesktopView
    ) {
      setShowToastUpdate(true);
      setShowEditButton(true);
      dispatch(setIsLifeStyleEditable(false));
      // dispatch(fetchLifeStyleRequest());
      // setIsEditable(false);
    }
    setTimeout(() => {
      setShowToastUpdate(false);
    }, 6000);
  }, [myLifeStyleSubmitResponse?.profileLifeStyleInfoStatus?.status]);

  const removeNomineeSubmitResp = useSelector(
    (state: RootState) => state?.osciReducer?.nominee
  );

  const handleMileageStatementNavigation = () => {
    navigate(`/${i18n.language}/myprofile/mileage-statement`);
  };

  useEffect(() => {
    if (profContactInfoSubmitResp.isUpdateSuccess === true) {
      setShowToastUpdate(true);
      setShowEditButton(true);
      dispatch(setIsEditable(false));
    }
    setTimeout(() => {
      setShowToastUpdate(false);
    }, 6000);
  }, [profContactInfoSubmitResp.isUpdateSuccess]);

  useEffect(() => {
    if (profPersonalInfoSubmitResp.isUpdateSuccess === true) {
      setShowToastUpdate(true);
      setShowEditButton(true);
      dispatch(setIsEditable(false));
    }
    setTimeout(() => {
      setShowToastUpdate(false);
    }, 6000);
  }, [profPersonalInfoSubmitResp.isUpdateSuccess]);

  useEffect(() => {
    if (removeNomineeSubmitResp.removeNomineeStatus.status === true) {
      setShowToastUpdate(true);
    }
    setTimeout(() => {
      setShowToastUpdate(false);
    }, 6000);
  }, [removeNomineeSubmitResp.removeNomineeStatus.status]);

  useEffect(() => {
    const handleBeforeUnload = (event: {
      preventDefault: () => void;
      returnValue: string;
    }) => {
      if (isPersonalEditable || isContactEditable || isLifestyleEditable) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isPersonalEditable, isContactEditable, isLifestyleEditable]);

  const handleEditClick = () => {
    if (selected === 1) {
      dispatch(setIsPersonalInfoEditable(true));
      setShowEditButton(false);
    }
    if (selected === 2) {
      dispatch(setIsEditable(true));
      setShowEditButton(false);
    }
    if (selected === 3) {
      dispatch(setIsLifeStyleEditable(true));
      setShowEditButton(false);
    }
    isCrm
      ? document.documentElement.scrollTo({ top: 700, behavior: "smooth" })
      : document.documentElement.scrollTo({ top: 850, behavior: "smooth" });
  };
  const handleRegisterClick = () => {
    navigate(`/${i18n.language}/signup`);
  };

  useEffect(() => {
    if (showModal) {
      document.documentElement.style.setProperty("--body-overflow", "hidden");
    } else {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    }
    return () => {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    };
  }, [showModal]);

  useEffect(() => {
    if (changePasswordResponse?.changePasswordStatus?.status === true) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setShowChangePasswordToast(true);
    }
    setTimeout(() => {
      setShowChangePasswordToast(false);
    }, 6000);
  }, [changePasswordResponse?.changePasswordStatus?.status]);

  return (
    <>
      <div className={styles.resetContainer}>
        <div
          className={
            isCrm
              ? styles.firstContainer1
              : privilageCard == "PLATINUM"
              ? styles.firstContainerPlatinum
              : privilageCard == "SILVER"
              ? styles.firstContainerSilver
              : privilageCard == "MEMBER"
              ? styles.firstContainerMember
              : privilageCard == "GOLD"
              ? styles.firstContainer
              : ""
          }
        >
          <div className={styles.titleContainer}>
            <>
              {isDesktopView && showToast && (
                <div style={{ marginBottom: "10%" }}>
                  <div className={styles.toast}>
                    <TGIcon icon="toast-icon" fillColor="" size="" />
                    {t("toast_message_profile")}
                  </div>
                </div>
              )}
            </>
            <>
              {isDesktopView && showToastUpdate && (
                <div style={{ marginBottom: "10%" }}>
                  <div className={styles.toast}>
                    <TGIcon icon="toast-icon" fillColor="" size="" />
                    {activeTab === 3
                      ? t("label_remove_nominee_success")
                      : t("toast_message_profile_updated")}
                  </div>
                </div>
              )}
            </>
            <>
              {isDesktopView && showChangePasswordToast && (
                <div style={{ marginBottom: "10%" }}>
                  <div className={styles.toast}>
                    <TGIcon icon="toast-icon" fillColor="" size="" />
                    {t("info_change_password_success")}
                  </div>
                </div>
              )}
            </>
            <div className={styles.firstContainerText}>
              {t("label_myprofile")}
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.profileContainer}>
            <div className={styles.subprofileContainer1}>
              <div
                className={
                  isCrm
                    ? styles.imageContainer1
                    : privilageCard == "PLATINUM"
                    ? styles.imageContainerPlatinum
                    : privilageCard == "SILVER"
                    ? styles.imageContainerSilver
                    : privilageCard == "MEMBER"
                    ? styles.imageContainerMember
                    : privilageCard == "GOLD"
                    ? styles.imageContainer
                    : ""
                }
              ></div>
              <div className={styles.profileimgContainer}>
                <TGIcon icon="profile-icon" size={""} fillColor={"none"} />
              </div>
              <div className={styles.profileContentContainer}>
                <div className={styles.memberDetailsContainer}>
                  <div className={styles.content1}>
                    {!isCrm && (
                      <div className={styles.membership}>
                        <div className={styles.memberId}>
                          {memberIdResponse.memberID}
                        </div>
                        <div
                          className={styles.memberIcon}
                          onClick={handleCopyClick}
                        >
                          <TGIcon
                            icon="member-icon"
                            size={""}
                            fillColor={"none"}
                          />
                        </div>
                      </div>
                    )}
                    <div className={styles.membershipName}>
                      {!isLoading && isCrm
                        ? `${crmProfileResponse?.salutation} ${crmProfileResponse?.name}`
                        : profileResponse?.fullName}
                    </div>
                  </div>
                  {isCrm && (
                    <div className={styles.crmContent2}>
                      {t("label_crm_registerropstatement")}
                    </div>
                  )}
                  {isCrm && (
                    <>
                      <div className={styles.crmContent3}>
                        <TGButtonVariants
                          label={t("label_crm_registerbuttontext")}
                          // iconName="arrow-right"
                          align="right"
                          size="25px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="30px"
                          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                          bgColor="#684B9B"
                          padding="10px 20px"
                          textColor="#FFF"
                          fontFamily="Inter"
                          fontSize="16px"
                          fontStyle="normal"
                          fontWeight="700"
                          lineHeight="160%"
                          borderDisabled="0.791px solid #E3D4FE"
                          bgColorDisabled="#381B6B"
                          boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                          bgColorHover="#381B6B"
                          borderHover="0.791px solid #E3D4FE"
                          boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                          height="50px"
                          width="100%"
                          onClick={handleRegisterClick}
                        ></TGButtonVariants>
                      </div>
                      {!isDesktopView && showToastUpdate && (
                        <div className={`${styles.crmToastMobile}`}>
                          <TGIcon icon="toast-icon" fillColor="" size="" />
                          {selected == 4
                            ? t("label_remove_nominee_success")
                            : t("toast_message_profile_updated")}
                        </div>
                      )}
                    </>
                  )}
                  {!isCrm && (
                    <>
                      <div className={styles.content2}>
                        {milesArr.map((item: any, index: any) => (
                          <div className={styles.contentTwoContainer}>
                            <div className={styles.expireText}>
                              {`${t("profile_expiring")} ${moment(
                                item?.milesExpiryDate
                              ).format("DD MMM YYYY")}:`}
                            </div>
                            <div className={styles.expireCount}>
                              {item?.amount}
                            </div>
                          </div>
                        ))}
                      </div>
                      {!isDesktopView && showToastUpdate && (
                        <div
                          className={`${
                            selected == 4
                              ? styles.toastMobile
                              : styles.toastMobile1
                          }`}
                        >
                          <TGIcon icon="toast-icon" fillColor="" size="" />
                          {selected == 4
                            ? t("label_remove_nominee_success")
                            : t("toast_message_profile_updated")}
                        </div>
                      )}
                      {!isDesktopView && showChangePasswordToast && (
                        <div className={`${styles.crmToastMobile}`}>
                          <TGIcon icon="toast-icon" fillColor="" size="" />
                          {t("info_change_password_success")}
                        </div>
                      )}
                      <div className={styles.content3}>
                        <div className={styles.mileage}>
                          <div className={styles.currentMileage}>
                            {t("profile_current_mileage")}
                          </div>
                          <div className={styles.currentMileageCOunt}>
                            {profileResponse?.currentMileage}
                          </div>
                        </div>
                        <div className={styles.mileageButton}>
                          <TGButtonVariants
                            label={t("profile_mileage_button")}
                            iconName="arrow-right"
                            align="right"
                            size="25px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="30px"
                            boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                            bgColor="#F5EFFF"
                            padding="16px"
                            textColor="#684B9B"
                            fontFamily="Inter"
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight="700"
                            lineHeight="160%"
                            borderDisabled="0.791px solid #E3D4FE"
                            bgColorDisabled="#E3D4FE"
                            boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                            borderHover="none"
                            boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                            bgColorHover="#F5EFFF"
                            height="50px"
                            width="100%"
                            textColorHover="#F5EFFF"
                            onClick={handleMileageStatementNavigation}
                          ></TGButtonVariants>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {!isLoading && (
              <div className={styles.subprofileContainer2}>
                {!isCrm && (
                  <>
                    <SideMenu
                      className={styles.sideMenuContainer}
                      title={sideMenuValues[0]}
                    />
                  </>
                )}
              </div>
            )}
            <DownloadApp
              isDesktop={device.isDesktop}
              isAndroid={device.isAndroid}
              isIOS={device.isIOS}
              setQrCode={setQrCode}
              setShowDownloadAppModal={setShowDownloadAppModal}
            />
            <TGModal
              show={showDownloadAppModal}
              handleClose={() => setShowDownloadAppModal(false)}
              centered={true}
              backdrop="static"
            >
              <div className={styles.downloadAppModalMain}>
                <div>
                  <p className={styles.downloadAppTitle}>
                    {t("label_download_thai_desktop_app")}
                  </p>
                  <div>
                    <p className={styles.downloadAppDescription}>
                      {t("label_download_thai_desktop_app_description")}
                    </p>
                    <p className={styles.downloadAppDescription}>
                      {t("label_download_thai_desktop_app_smooth")}
                    </p>
                  </div>
                  <div className={styles.downloadAppImages}>
                    <img src={AppStore} alt="AppStore" />
                    <img src={PlayStore} alt="PlayStore" />
                    <img src={HauweiStore} alt="HauweiStore" />
                  </div>
                </div>
                <div className={styles.qrContainer}>
                  <img
                    src={
                      qrCode === "App Store"
                        ? `${process.env.S3BUCKET_URL}${config.IOS_APP_QR}`
                        : `${process.env.S3BUCKET_URL}${config.ANDROID_APP_QR}`
                    }
                    alt={qrCode === "App Store" ? "ios-app" : "android-app"}
                    className={styles.qrImage}
                  />
                  <p className={styles.downloadScan}>
                    {t("label_download_thai_desktop_app_scan")}
                  </p>
                </div>
              </div>
            </TGModal>
          </div>
          {isDesktopView && (
            <div className={styles.contentContainer}>
              <div className={styles.menubar}>
                <div
                  className={`${
                    activeTab === 0 ? styles.tabOneContainer : styles.default
                  }`}
                  onClick={() => handleTabClick(0)}
                >
                  <div>
                    <TGIcon icon="personal-icon" fillColor="" size="" />
                  </div>
                  <div
                    className={
                      activeTab === 0
                        ? styles.heading1
                        : styles.headingNotSelected
                    }
                  >
                    {t("profile_personal_info")}
                  </div>
                </div>
                <div
                  className={`${
                    activeTab === 1
                      ? isCrm
                        ? styles.crmContactInfo
                        : styles.tabTwoContainer
                      : styles.default
                  }`}
                  onClick={() => handleTabClick(1)}
                >
                  <div>
                    <TGIcon icon="contact-icon" fillColor="" size="" />
                  </div>
                  <div
                    className={
                      activeTab === 1
                        ? styles.heading1
                        : styles.headingNotSelected
                    }
                  >
                    {t("profile_contact_info")}
                  </div>
                </div>
                {!isCrm && (
                  <div
                    className={`${
                      activeTab === 2
                        ? styles.tabThreeContainer
                        : styles.default
                    }`}
                    onClick={() => handleTabClick(2)}
                  >
                    <div>
                      <TGIcon icon="lifestyle-icon" fillColor="" size="" />
                    </div>
                    <div
                      className={
                        activeTab === 2
                          ? styles.heading1
                          : styles.headingNotSelected
                      }
                    >
                      {t("profile_Lifestyle_Info")}
                    </div>
                  </div>
                )}
                {!isCrm && (
                  <div
                    className={`${
                      activeTab === 3 ? styles.tabFourContainer : styles.default
                    }`}
                    onClick={() => handleTabClick(3)}
                  >
                    <div>
                      <TGIcon icon="nominee-icon" fillColor="" size="" />
                    </div>
                    <div
                      className={
                        activeTab === 3
                          ? styles.heading1
                          : styles.headingNotSelected
                      }
                    >
                      {t("label_nominee_header")}
                    </div>
                  </div>
                )}
              </div>
              {activeTab === 0 && (
                <div className={styles.contentSub}>
                  <PersonalInfo profileName={isCrm ? "CRM" : "ROP"} />
                </div>
              )}
              {activeTab === 1 && (
                // <div className={styles.contentSub}>
                <ContactInfo profileName={isCrm ? "CRM" : "ROP"} />
                // </div>
              )}
              {activeTab === 2 && (
                // <div className={styles.contentSub}>
                <LifeStyle />
                // </div>
              )}
              {activeTab === 3 && (
                <div className={styles.contentSub}>
                  <NomineeInfo />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {!isDesktopView && (
        <>
          <div
            ref={profileScrollRef}
            className={`${styles.profileCOntentMobile} ${
              isProfile ? styles.animatedHeader : styles.staticHeader
            }`}
          >
            <div className={styles.profileMobile}>
              <Form.Group
                // controlId={"exampleForm.ControlInput"}
                className={styles.passengerContainer}
                title="MY PROFILE"
              >
                <span
                  className={styles["tg-select-passenger-arrow-icon"]}
                  onClick={() => {
                    inputRef.current.focus();
                  }}
                >
                  <TGIcon icon="chevron-down-icon" fillColor="" size="16" />
                </span>
                <div>
                  <Form.Control
                    className={`${styles["tg-select-passenger-box"]} `}
                    onChange={() => {}}
                    onFocus={(e: any) => {
                      setIsOpen(true);
                      document.documentElement.style.setProperty(
                        "--carousel-z-index",
                        "-1"
                      );
                      !isDesktopView && inputRef.current.blur();
                      isDesktopView && e?.target?.scrollIntoView();
                    }}
                    ref={inputRef}
                    value={value}
                    readOnly={true}
                  />
                </div>
                {isOpen && renderMenu()}
              </Form.Group>
            </div>
          </div>

          <>
            {selected == 1 && (
              <div
                className={
                  isProfile ? styles.animatedContent : styles.staticContent
                }
              >
                <PersonalInfo profileName={isCrm ? "CRM" : "ROP"} />
              </div>
            )}
            {selected == 2 && (
              <>
                <ContactInfo profileName={isCrm ? "CRM" : "ROP"} />
              </>
            )}
            {selected == 3 && (
              <>
                <LifeStyle />
              </>
            )}
            {selected == 4 && (
              <>
                <NomineeInfo />;
              </>
            )}
          </>
          {showToast && (
            <div className={`${styles.toastMobileCopy}`}>
              <TGIcon icon="toast-icon" fillColor="" size="" />
              {t("toast_message_profile")}
            </div>
          )}
          {selected !== 4 && showEditButton && (
            <div
              ref={editScrollRef}
              style={{ marginBottom: "20px" }}
              className={`${
                isProfile ? styles.animatedButton : styles.staticButton
              }${styles.editButton}`}
            >
              <TGButtonVariants
                label={" " + t("label_edit")}
                display="flex"
                iconName="edit-icon"
                fillColor=""
                size=""
                align="left"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                bgColor="#F5EFFF"
                padding={isDesktopView ? "16px" : "14px 35px 14px 10px"}
                textColor="#684B9B"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                borderDisabled="0.791px solid #E3D4FE"
                bgColorDisabled="#E3D4FE"
                boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                bgColorHover="#684b9b"
                borderHover="0.791px solid #E3D4FE"
                boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                height="50px"
                width="100%"
                onClick={handleEditClick}
              />
            </div>
          )}
        </>
      )}
      {showModal && (
        <TGModal
          show={showModal}
          handleClose={handleCloseModal}
          buttonLabel="Ok"
          customModalClass={styles.customModalClass}
          customOffCanvasClass={styles.customOffCanvasClass}
          centered={true}
          backdrop="static"
        >
          <div className={styles.tcModalFlex}>
            <div className={styles.tcModal}>
              <TGIcon fillColor={"none"} icon="duplicate-nominee-icon" />
              <div className={styles.modalContent}>
                <span className={styles.modalHeading}>
                  {t("info_exit_mode_heading")}
                </span>
                <span className={styles.modalDescription}>
                  {t("info_exit_mode_message")}
                </span>
              </div>
            </div>
            {isDesktopView ? (
              <div className={styles.buttonContainer}>
                <TGButtonVariants
                  label={t("button_exit_mode_leave")}
                  disabled={false}
                  display="flex"
                  fillColor=""
                  size=""
                  align="left"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  bgColor="#F5EFFF"
                  padding={isDesktopView ? "16px" : "14px 10px 14px 10px"}
                  textColor="#684B9B"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  bgColorHover="#F5EFFF"
                  height="50px"
                  width="128px"
                  onClick={handleLeaveModal}
                />
                <TGButtonVariants
                  label={t("button_exit_mode_stay")}
                  disabled={false}
                  display="flex"
                  fillColor=""
                  size=""
                  align="left"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  bgColor="#684B9B"
                  padding={isDesktopView ? "16px" : "14px 10px 14px 10px"}
                  textColor="#FFF"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  bgColorHover="#684B9B"
                  height="50px"
                  width="170px"
                  onClick={handleStayModal}
                />
              </div>
            ) : (
              <div className={styles.buttonContainer}>
                <TGButton
                  label={t("button_exit_mode_stay")}
                  variant="primary"
                  buttonSize="sm"
                  customClass="bookingButtonCheckIn"
                  onClick={handleStayModal}
                  disabled={false}
                />
                <TGButton
                  label={t("button_exit_mode_leave")}
                  variant="secondary"
                  buttonSize="sm"
                  customClass="bookingButtonCheckIn"
                  onClick={handleLeaveModal}
                  disabled={false}
                />
              </div>
            )}
          </div>
        </TGModal>
      )}
    </>
  );
};
